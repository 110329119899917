<template>
  <div>
    <div :class="system.isPhone ? 'wap_apply_form_all' : 'apply_form_all'">
      <el-form
        ref="applyAgentForm"
        :label-position="system.isPhone ? 'top' : 'right'"
        label-width="auto"
        :model="form"
        :rules="rules"
        size="mini"
      >
        <form-bar :whoApply="whoApply" name=".firstName"></form-bar>

        <div
          class="form_css"
          :class="$t('languages') == 'EN' ? 'form_css_en' : ''"
        >
          <!-- 境内/外公司名称 -->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="companyName"
            class="text_line"
            v-model="form.companyName"
            :label="$t('applyAgent.' + whoApply + '.companyName')"
          ></text-input>
          <!-- 邮箱填写 -->
          <text-input
            prop="email"
            class="text_line"
            v-model="form.email"
            :label="$t('applyAgent.' + whoApply + '.email')"
          ></text-input>

          <!-- 申请入驻地址 3-->
          <el-form-item
            v-if="whoApply == 'provinceApply' || whoApply == 'cityApply'"
            :prop="whoApply == 'cityApply' ? 'city' : 'province'"
            :label="$t('applyAgent.' + whoApply + '.region')"
          >
            <el-row>
              <el-col :span="8">
                <el-select
                  class="area_select"
                  v-model="form.province"
                  :placeholder="$t('applyAgent.' + whoApply + '.province')"
                  autocomplete="off"
                  @change="areaChangeEvent"
                >
                  <el-option
                    v-for="item in areaData"
                    :key="item.id"
                    :label="item.label"
                    :value="item.areaName"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8" v-if="whoApply == 'cityApply'">
                <el-select
                  class="area_select"
                  v-model="form.city"
                  :placeholder="$t('applyAgent.' + whoApply + '.city')"
                  autocomplete="off"
                >
                  <el-option
                    v-for="item in cityData"
                    :key="item.id"
                    :label="item.areaName"
                    :value="item.areaName"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>

          <!-- <text-input
            v-if="whoApply != 'personalApply'"
            prop="telphone"
            class="text_line"
            v-model="form.telphone"
            :label="$t('applyAgent.' + whoApply + '.telphone')"
          ></text-input> -->

          <!-- 电话号码 2-->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="telphone"
            class="text_line"
            v-model="form.telphone"
            :label="$t('applyAgent.' + whoApply + '.telphone')"
          ></text-input>

          <!-- 公司税号 2-->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="dutyParagraph"
            class="text_line"
            v-model="form.dutyParagraph"
            :label="$t('applyAgent.' + whoApply + '.dutyParagraph')"
          ></text-input>

          <!-- 开户银行 2-->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="companyName"
            class="text_line"
            v-model="form.bankName"
            :label="$t('applyAgent.' + whoApply + '.bankName')"
          ></text-input>

          <!-- 银行账号 2-->
          <text-input
            v-if="whoApply != 'personalApply'"
            prop="companyName"
            class="text_line"
            v-model="form.cardNumber"
            :label="$t('applyAgent.' + whoApply + '.cardNumber')"
          ></text-input>

          <!-- 上传单位证件图片 -->
          <uplode-input
            v-if="whoApply != 'personalApply'"
            prop="businessLicenseUrl"
            :label="$t('applyAgent.' + whoApply + '.businessLicenseUrl')"
            keyName="businessLicenseUrl"
            @uplodeChange="uplodeChange"
          ></uplode-input>
        </div>
        <div class="zanwei"></div>

        <form-bar :whoApply="whoApply" name=".secondName"></form-bar>

        <div
          class="form_css"
          :class="$t('languages') == 'EN' ? 'form_css_en' : ''"
        >
          <!-- 身份证姓名 -->
          <text-input
            prop="idcardName"
            class="text_line"
            v-model="form.idcardName"
            :label="$t('applyAgent.' + whoApply + '.idcardName')"
          ></text-input>

          <!-- 电话号码 1-->
          <text-input
            v-if="whoApply == 'personalApply'"
            prop="telphone"
            class="text_line"
            v-model="form.telphone"
            :label="$t('applyAgent.' + whoApply + '.telphone')"
          ></text-input>

          <!-- 身份证号 -->
          <text-input
            prop="idcardNumber"
            class="text_line"
            v-model="form.idcardNumber"
            :label="$t('applyAgent.' + whoApply + '.idcardNumber')"
          ></text-input>

          <!-- 开户银行 1-->
          <text-input
            v-if="whoApply == 'personalApply'"
            prop="bankName"
            class="text_line"
            v-model="form.bankName"
            :label="$t('applyAgent.' + whoApply + '.bankName')"
          ></text-input>

          <!-- 银行账号 1-->
          <text-input
            v-if="whoApply == 'personalApply'"
            prop="cardNumber"
            class="text_line"
            v-model="form.cardNumber"
            :label="$t('applyAgent.' + whoApply + '.cardNumber')"
          ></text-input>

          <uplode-input
            prop="idcardFrontUrl"
            :label="$t('applyAgent.' + whoApply + '.idcardALL')"
            keyName="idcardFrontUrl"
            @uplodeChange="uplodeChange"
          ></uplode-input>
          <uplode-input
            prop="idcardBackUrl"
            keyName="idcardBackUrl"
            @uplodeChange="uplodeChange"
          ></uplode-input>
          <uplode-input
            prop="idcardHandUrl"
            keyName="idcardHandUrl"
            @uplodeChange="uplodeChange"
          ></uplode-input>
        </div>
        <form-bar
          v-if="!system.isPhone"
          textNone="0"
          :whoApply="whoApply"
          name=".secondName"
        ></form-bar>

        <div
          class="form_css"
          :class="$t('languages') == 'EN' ? 'form_css_en' : ''"
        >
          <!-- 描述-->
          <text-input
            prop="memo"
            inputType="textarea"
            class="text_line"
            v-model="form.memo"
            :maxlength="200"
            :label="$t('applyAgent.' + whoApply + '.memo')"
          ></text-input>
        </div>
        <el-form-item>
          <el-button
            class="next_but "
            type="primary"
            @click="submitForm('applyAgentForm')"
            >{{ $t("applyAgent.errMsg.nextStep") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import textInput from "@/views/formComponents/textInput";
import uplodeInput from "@/views/formComponents/uplodeInput";
import formBar from "./formBar";
import { verifyPhone, verifyEmail } from "@/utils/pattern.js";

export default {
  components: {
    formBar,
    textInput,
    uplodeInput
  },
  data() {
    var phone = (rule, value, callback) => {
      if (!verifyPhone(value, `+${this.ruleForm.areaCode}`)) {
        this.canSendCode = "false";
        callback(new Error(this.$t("nameInputPhone")));
      } else {
        this.canSendCode = "true";
        callback();
      }
    };
    var email = (rule, value, callback) => {
      if (!verifyEmail(value)) {
        callback(new Error(this.$t("applyAgent.errMsg.emailnotallow")));
      } else {
        callback();
      }
    };
    var notNull = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t("applyAgent.errMsg.notNull")));
      } else {
        callback();
      }
    };
    var img = (rule, value, callback) => {
      if (this.form[rule.field].indexOf("http") < 0) {
        callback(new Error(this.$t("applyAgent.errMsg.notNull")));
      } else {
        callback();
      }
    };

    return {
      areaData: [], // 省市数据
      cityData: [], //市数据
      whoApply: this.$route.query.whoApply || "personalApply",
      form: {
        idcardName: "",
        idcardNumber: "",
        idcardFrontUrl: "",
        idcardBackUrl: "",
        idcardHandUrl: "",
        // phone: "13296589966",
        email: "",
        companyName: "",
        // companyAddress: "杭州滨江云狐科技园",
        businessLicenseUrl: "",
        // physicalAddress: "实体店地址",
        // physicalImgUrl: "实体店照片",
        businessLine: 1, //1:线上  2：线下 3: 澳新购 4：澳新通
        kind: "ChinaCompany", //个人Personal;中国企业ChinaCompany; 澳洲企业AustraliaCompany
        memo: "",
        telphone: "",
        cardNumber: "",
        bankName: "",
        dutyParagraph: "",
        province: "",
        city: "",
        region: "",
        bankCode: ""
      },
      canSendCode: "false",
      rules: {
        phone: [{ required: true, validator: phone, trigger: "blur" }],
        telphone: [{ required: true, validator: notNull, trigger: "blur" }],
        bankName: [{ required: true, validator: notNull, trigger: "blur" }],
        cardNumber: [{ required: true, validator: notNull, trigger: "blur" }],
        dutyParagraph: [
          { required: true, validator: notNull, trigger: "blur" }
        ],
        province: [{ required: true, validator: notNull, trigger: "blur" }],
        city: [{ required: true, validator: notNull, trigger: "blur" }],
        // region: [{ required: true, validator: notNull, trigger: "blur" }],
        bankCode: [{ required: true, validator: notNull, trigger: "blur" }],
        email: [{ required: true, validator: email, trigger: "blur" }],
        companyName: [{ required: true, validator: notNull, trigger: "blur" }],
        idcardName: [{ required: true, validator: notNull, trigger: "blur" }],
        idcardNumber: [{ required: true, validator: notNull, trigger: "blur" }],
        businessLicenseUrl: [{ required: true, validator: img }],
        idcardFrontUrl: [{ required: true, validator: img }],
        idcardBackUrl: [{ required: true, validator: img }],
        idcardHandUrl: [{ required: true, validator: img }]
      }
    };
  },
  mounted() {},
  methods: {
    // getAreaData() {
    //   this.$request("/api/fadmin/order/address/getAllAddress", {
    //     method: "GET"
    //   })
    //     .then(res => {
    //       this.areaData = res.data;
    //     })
    //     .catch(() => {});
    // },
    areaChangeEvent(areaName) {
      if (this.whoApply === "provinceApply") {
        return;
      }
      this.form.city = "";
      this.$nextTick();
      this.areaData.map(item => {
        if (item.areaName === areaName) {
          this.cityData = item.childList;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tijiao();
        } else {
          // this.$message({
          //   message: "有必填项未填写",
          //   type: "warning"
          // });
          // return false;
        }
      });
    },
    uplodeChange(value) {
      this.form = { ...this.form, ...value };
      this.$refs["applyAgentForm"].validateField(Object.keys(value)[0]);
    },
    tijiao() {
      let data = { ...this.form };
      if (this.whoApply === "personalApply") {
        //个人申请则移除多余字段,，确定个人还是企业
        delete data.companyName;
        delete data.companyAddress;
        delete data.businessLicenseUrl;

        delete data.dutyParagraph;
        delete data.province;
        delete data.city;
        delete data.region;
        delete data.bankCode;

        data.kind = "Personal";
      }
      if (this.whoApply === "companyApply") {
        // 线上企业申请
        delete data.province;
        delete data.city;
        delete data.region;
        delete data.bankCode;
      }
      if (this.whoApply === "provinceApply") {
        // 线上企业申请
        data.region = {
          province: data.province
        };

        delete data.province;
        delete data.city;
        delete data.bankCode;
      }
      if (this.whoApply === "cityApply") {
        // 线上企业申请
        data.region = {
          province: data.province,
          city: data.city
        };
        delete data.province;
        delete data.city;
        delete data.bankCode;
      }
      //确定线上还是线下
      if (this.whoApply === "provinceApply" || this.whoApply === "cityApply") {
        data.businessLine = 2;
      }
      //确定省级还是地级代理
      if (this.whoApply === "provinceApply") {
        data.level = 5;
      } else if (this.whoApply === "cityApply") {
        data.level = 6;
      }
      //进行提交
      this.$request("/api/front/userInfoCheck/addUserInfoCheck", {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            this.$router.replace({
              path: "applyAfter",
              query: { status: "daishenhe" }
            });
            this.$message({
              message: "提交成功，等待审核",
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.zanwei {
  height: 60px;
}
.area_select {
  margin-right: 20px;
}
.next_but {
  width: 272px;
  height: 53px;
  border-radius: 67px;
  margin-top: 60px;
}
.apply_form_all {
  width: 100%;
  .text_line {
    // padding-top: 18px;
    // padding-bottom: 18px;
  }
  /deep/.form_css {
    max-width: 660px;
    margin: auto;
    .el-textarea__inner.el-textarea__inner {
      height: 100px;
    }
  }
  .form_css_en {
    max-width: 770px;
  }
}
.wap_apply_form_all {
  .zanwei {
    height: calc(100vw * 1.2 / 37.5);
  }
  /deep/.el-form-item__content {
    text-align: center;
  }
  .next_but {
    width: calc(100vw * 32.5 / 37.5);
    height: calc(100vw * 4.2 / 37.5);
    opacity: 1;
    background: #1977fb;
    border-radius: 6px;
    margin: auto;
  }
  /deep/.form_css {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;

    .el-textarea__inner {
      height: calc(100vw * 12 / 37.5);
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
    .el-form-item__label {
      font-size: calc(100vw * 1.2 / 37.5);
      padding: 0;
      margin-top: calc(100vw * 2.4 / 37.5);
    }
    .el-input__inner {
      font-size: calc(100vw * 1.2 / 37.5);
      height: auto;
      line-height: calc(100vw * 3.2 / 37.5);
      border-radius: 0px;
      border: 1px none #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
    }
    .uplode,
    .uplode_icon {
      width: calc(100vw * 15 / 37.5);
      height: calc(100vw * 10 / 37.5);
    }
  }
}
</style>
