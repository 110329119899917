<template>
  <div class="">
    <el-form-item :prop="prop" :label="label">
      <el-row class="yihuang" :gutter="13">
        <el-col :span="7">
          <el-upload
            class="uplode"
            :class="!system.isPhone ? 'pc_xianzhi' : ''"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeUpload"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="uplode_icon"
              :class="!system.isPhone ? 'pc_xianzhi' : ''"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
        <el-col :span="17">
          <img
            class="uplode_icon"
            :class="!system.isPhone ? 'pc_xianzhi' : ''"
            :src="require('@/assets/img/apply/' + keyName + '_shili.png')"
          />
        </el-col>
      </el-row>
      <!-- <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog> -->
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  props: ["value", "prop", "inputType", "p", "keyName", "label"],
  components: {},
  data() {
    return {
      imageUrl: require("@/assets/img/apply/" + this.keyName + ".png"),
      info: {},
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [
        {
          name: "food.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        },
        {
          name: "food2.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        }
      ],

      valueData: this.value,
      new_inputType: this.inputType
    };
  },

  computed: {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或Png 格式!");
        return false;
      }

      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }

      this.uploading = true;
      const formData = new FormData();
      // const data = {
      //   kind: "websiteQualification"
      // };
      formData.append("file", file);
      formData.append("kind", "websiteQualification");
      formData.append("sourceId", 1);
      this.$request("/api/front/daemon/files/upload", {
        method: "POST",
        data: formData,
        requestType: "form"
      })
        .then(res => {
          console.log(res);
          this.uploading = false;
          if (res.code === 1) {
            this.imageUrl = res.data.linkUrl;
            this.change(res.data.linkUrl);
          }
        })
        .catch(err => {
          console.log(err);
          this.uploading = false;
        });
      return false;
    },
    change(value) {
      console.log(value);
      this.$emit("uplodeChange", {
        [this.keyName]: value
      });
    },
    changeInputTypeEvent() {
      this.new_inputType == "text"
        ? (this.new_inputType = "password")
        : (this.new_inputType = "text");
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
/deep/.yihuang {
  display: flex;

  overflow: hidden;
  .el-col-7 {
    width: auto;
  }
}
/deep/.uplode {
  width: 240px;
  height: 162px;
  .uplode_icon {
    width: 100%;
    height: 100%;
  }
  .el-upload--picture-card {
    height: 100%;
    width: 100%;
  }
}
.pc_xianzhi {
  max-width: calc(100vw * 24 / 192);
  max-height: calc(100vw * 16.2 / 192);
}
.uplode_icon {
  width: 240px;
  height: 162px;
  display: flex;
  object-fit: contain;
}
</style>
